import React from "react"
import { Link } from "gatsby"

const Pager = props => {
  const pageContext = props.page
  const pageNum = pageContext.humanPageNumber

  return (
    <div className="pager">
      {pageContext.previousPagePath ? (
        <Link
          className="pager__arrow pager__arrow--prev"
          to={pageContext.previousPagePath}
        >
          Preview
        </Link>
      ) : (
        ""
      )}
      <ul className="pager__list">
        {pageContext.previousPagePath ? (
          <li className="pager__item">
            <Link to={pageContext.previousPagePath} className="pager__link-num">
              {pageNum - 1}
            </Link>
          </li>
        ) : (
          ""
        )}
        <li className="pager__item is-current">
          <p className="pager__link-num">{pageNum}</p>
        </li>
        {pageContext.nextPagePath ? (
          <li className="pager__item">
            <Link to={pageContext.nextPagePath} className="pager__link-num">
              {pageNum + 1}
            </Link>
          </li>
        ) : (
          ""
        )}
      </ul>
      {pageContext.nextPagePath ? (
        <Link
          className="pager__arrow pager__arrow--next"
          to={pageContext.nextPagePath}
        >
          Next
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}

export default Pager
