import React from "react"
import Layout from "../../components/common/layout"
import Meta from "../../components/common/meta"
import Pager from "../../components/pager/pager"
import PageTitle from "../../components/pageTitle/pageTitle"

const Item = props => {
  const array = []
  const archive = props.archive
  const type = props.type
  let titleJa, titleEn

  archive.map((v, i) => {
    const fullDate = v.date
    let date = ""
    if (fullDate) {
      if (type === "media") {
        date =
          Number(fullDate.split("-")[0]) + "." + Number(fullDate.split("-")[1])
      } else {
        date = Number(fullDate.split("-")[0])
      }
    }
    if (v.title_ja) {
      titleJa = (
        <span
          className="ja"
          dangerouslySetInnerHTML={{ __html: v.title_ja[0].text }}
        />
      )
    } else {
      titleJa = ""
    }
    if (v.title_en) {
      titleEn = (
        <span
          className="ja"
          dangerouslySetInnerHTML={{ __html: v.title_en[0].text }}
        />
      )
    } else {
      titleEn = ""
    }

    array.push(
      <li key={i} className="archive__item" data-scroll="">
        <div className="archive__block" to="/">
          <div className="archive__info">
            <span className="archive__date en">{date}</span>
          </div>
          <h2 className="archive__text">
            {titleEn}
            {titleJa}
          </h2>
        </div>
      </li>
    )
    return true
  })

  return array
}

const archivePage = props => {
  const { pageContext } = props
  const posts = pageContext.posts
  const currentNumber = pageContext.pageNumber
  const currentPosts = posts.slice(
    pageContext.limit * currentNumber,
    pageContext.limit * (1 + currentNumber)
  )
  const pageType = pageContext.data.page_en[0].text
  const archiveClass = ["archive", pageType]
  const metaTitile = pageType + " | 1moku co."
  const metaDesc =
    "hirofumi sugaを中心に庭園デザインをはじめ、庭師の視点でとらえた空間デザイン、ランドスケープデザインなどを手掛けている1moku co.の" +
    pageContext.data.page_ja[0].text +
    "についてご紹介します。"

  return (
    <Layout page="about-archive">
      <Meta
        title={metaTitile}
        description={metaDesc}
        url="https://www.1moku.co.jp/media/"
        type="article"
      />
      <PageTitle title={pageContext.data.page_en[0].text} />
      <div className={archiveClass.join(" ")}>
        <div className="archive__head">
          <h1 className="archive__title" data-scroll="">
            <span className="en">{pageContext.data.page_en[0].text}</span>
            <span className="ja">{pageContext.data.page_ja[0].text}</span>
          </h1>
        </div>
        <div className="archive__body">
          <ul className="archive__list">
            <Item archive={currentPosts} type={pageType} />
          </ul>
          <div className="archive-body__pager">
            <Pager page={pageContext} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default archivePage
